<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.parameters.title") }}</h3>
    <file-uploader ref="parameterLogo" :multiple="false" @setFiles="setLogo" />
    <div class="font-weight-bold mb-3">
      {{ $t("page.parameters.addLogo") }}
    </div>
    <div v-if="invalidImage" class="mb-3 text-red">
      {{ $t(`page.parameters.${invalidImage}`) }}
    </div>
    <div class="no-gutters image-container">
      <img
        v-if="!parameters.logo"
        class="clickable mr-2"
        style="width: 115px"
        src="./../assets/1board_addLogo.svg"
        @click="openUploadDialog"
      />
      <div v-else class="image-wrapper mr-3">
        <b-img :src="parameters.logo" class="image-item" />
        <b-icon
          class="custom-badge clickable"
          icon="trash-fill"
          color="white"
          @click="removeLogo"
        />
      </div>
    </div>
    <div class="mt-5">
      <b-row class="no-gutters justify-content-between align-items-center mb-3">
        <b-col class="col-auto">
          <h4 class="font-weight-normal mb-0">
            {{ $t("page.parameters.categories") }}
          </h4>
        </b-col>
        <b-col class="col-auto">
          <b-button
            class="float-right mb-3"
            size="md"
            variant="secondary"
            @click="openCreateCategoryModal"
            v-b-modal.create-category-modal
          >
            {{ $t("page.parameters.newCategory") }}
          </b-button>
        </b-col>
      </b-row>
      <infinite-scroll @load="retrieveCategories">
        <category-table
          :categories="categories"
          :fields="fields"
          :sortBy.sync="sortBy"
          :sortDesc.sync="sortDesc"
          @manage="editCategoryModal"
          no-local-sorting
        />
      </infinite-scroll>
      <create-category
        :state="createModalState"
        :callbackAfterCreate="retrieveCategories"
        :defaultCategory="selectedCategory"
        @close="closeCreateCategoryModal"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";

import FileUploader from "../components/Base/BaseFileUploader.vue";
import CategoryTable from "../components/Parameters/CategoryTable.vue";
import CreateCategory from "../components/Parameters/CreateCategory.vue";
import InfiniteScroll from "./../components/Base/BaseInfiniteScroll";
import { authMixin } from "./../mixins";

export default {
  components: {
    FileUploader,
    CategoryTable,
    CreateCategory,
    InfiniteScroll
  },
  mixins: [authMixin],
  data() {
    return {
      createModalState: false,
      selectedCategory: null,

      fields: [
        {
          key: "description",
          label: "name",
          sort: true,
          sortBy: "description",
          thStyle: { width: "25%" }
        },
        { key: "active", label: "status", thStyle: { width: "25%" } },
        { key: "details", label: "", thStyle: { width: "50%" } }
      ],
      parameters: {
        logo: ""
      },
      invalidImage: null
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadParameters", () =>
      this.retrieveCategories(true)
    );
    this.parameters.logo = this.logo;
    this.retrieveCategories(true);
  },
  computed: {
    categories() {
      return this.$store.getters["parameters/getCategories"];
    },
    activeAdmin() {
      return this.$store.getters["filter/getAdministratorSelected"];
    },
    logo: {
      get() {
        return this.$store.getters["parameters/getAdminLogo"];
      },
      set(value) {
        this.setParametersLogo(value);
      }
    },
    sortBy: {
      get() {
        const sortBy = this.$store.getters["parameters/getSortBy"];
        return this.fields.find(f => f.sortBy === sortBy)?.key || sortBy;
      },
      set(value) {
        const sortBy = this.fields.find(f => f.key === value)?.sortBy || value;
        this.$store.commit("parameters/SET_SORT_BY", sortBy);
        this.retrieveCategories(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["parameters/getSortDesc"];
      },
      set(value) {
        this.$store.commit("parameters/SET_SORT_DESC", value);
        this.retrieveCategories(true);
      }
    }
  },
  methods: {
    openUploadDialog() {
      this.$refs.parameterLogo.openSelectFileWindow();
    },
    setLogo(file) {
      this.logo = file;
    },
    async setParametersLogo(file) {
      const fileToSend = file[0];
      if (!fileToSend) return;
      this.parameters.logo = URL.createObjectURL(fileToSend);
      this.$store.commit("loader/SET_LOADER", {});
      setTimeout(async () => {
        try {
          const img = document.querySelector(".image-item");
          if (
            this.$_authUserIsDomi &&
            !this.$store.getters["filter/getAdministratorSelected"]
          )
            throw Error("invalidAdminMessage");
          if (img.naturalWidth !== 230 || img.naturalHeight !== 60)
            throw Error("invalidImageMessage");

          this.invalidImage = null;
          await this.$store.dispatch("parameters/uploadLogo", {
            administratorId: this.$_authUserIsDomi
              ? this.activeAdmin.profile.administratorId
              : this.$store.getters["auth/getAdminInfo"].profile
                  .administratorId,
            payload: { logo: fileToSend }
          });
          await this.$store.dispatch(
            "auth/retrieveUser",
            this.$store.getters["auth/getUserId"]
          );
        } catch (error) {
          if (error.message) this.invalidImage = error.message;
          this.parameters.logo = null;
        } finally {
          this.$store.commit("loader/RESET");
        }
      }, 300);
    },
    async removeLogo() {
      this.$store.commit("loader/SET_LOADER", {});
      try {
        await this.$store.dispatch(
          "parameters/deleteLogo",
          this.$_authUserIsDomi
            ? this.activeAdmin.profile.administratorId
            : this.$store.getters["auth/getAdminInfo"].profile.administratorId
        );
        await this.$store.dispatch(
          "auth/retrieveUser",
          this.$store.getters["auth/getUserId"]
        );
        this.parameters.logo = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    openCreateCategoryModal() {
      this.selectedCategory = null;
      this.createModalState = true;
    },
    editCategoryModal(item) {
      this.selectedCategory = item;
      this.createModalState = true;
    },
    closeCreateCategoryModal() {
      this.createModalState = false;
      this.selectedCategory = null;
    },
    async retrieveCategories(initial) {
      try {
        if (initial) {
          this.$store.commit("loader/SET_LOADER", {});
          await this.$store.dispatch("parameters/retrieveAdminLogo");
          this.parameters.logo = this.logo;
          this.invalidImage = null;
        }
        await this.$store.dispatch("parameters/retrieveCategories", {
          hasPagination: true,
          initial
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadParameters");
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-wrap: nowrap;
}
.image-wrapper {
  position: relative;
}
.image-item {
  border-radius: 10px;
  width: 115px;
  height: 100%;
}
.custom-badge {
  position: absolute;
  font-size: 20px;
  padding: 4px;
  bottom: 0px;
  right: -10px;
  background: var(--red);
  border-radius: 25px;
}
</style>
