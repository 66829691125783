<template>
  <div class="infinite-scroll-container">
    <slot />
    <div
      v-if="fetching"
      class="d-flex justify-content-center"
      style="gap: 10px"
    >
      <b-spinner
        type="grow"
        variant="orange"
        style="display: block;"
        small
      ></b-spinner>
      <b-spinner
        type="grow"
        variant="orange"
        style="display: block;"
        small
      ></b-spinner>
      <b-spinner
        type="grow"
        variant="orange"
        style="display: block;"
        small
      ></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetching: false
    };
  },
  mounted() {
    this.initializeInfiniteScroll();
  },
  props: {
    loading: {
      type: Boolean
    },
    container: {
      type: String,
      default: ".page-container"
    },
    debounce: {
      type: Number,
      default: 1000
    },
    canScroll: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    initializeInfiniteScroll() {
      if (!this.canScroll) return;
      window.fetching = false;
      window.addEventListener("scroll", this.scrollListener);
    },
    scrollListener() {
      if (window.fetching) return;
      const container = document.querySelector(this.container);
      const bottomOfWindow =
        window.parent.innerHeight + 50 >=
        container.getBoundingClientRect().bottom;
      if (bottomOfWindow) {
        try {
          window.fetching = true;
          this.$emit("load");
        } catch (error) {
          console.error(error);
        } finally {
          setTimeout(() => {
            window.fetching = false;
          }, this.debounce);
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style></style>
